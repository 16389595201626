"use client";

import { apiPost, apiPostLike, apiPostUnlike, apiTrack, apiUserFollow, apiUserUnfollow } from "@/lib/api-method";
import { Button } from "react-bootstrap";
import { IPost } from "@/models/post";
import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import ContentComponent from "@/components/content";
import dayjs from "dayjs";
import PostUserComponent from "@/components/post/user";
import relativeTime from "dayjs/plugin/relativeTime";
import ToolComponent from "@/components/post/tool";

dayjs.extend(relativeTime);

export default function Component({ post }: { post: IPost | null } ) {
  const [_post, setPost] = useState<IPost | null>(post);
  const pathname = usePathname();

  useEffect(() => {
    if (_post == null) {
      var id = pathname.split("/").pop();
      if (id != null) {
        apiPost(id).then(([status, post]) => {
          setPost(post);
        });
      }
    }
  }, [_post, pathname]);

  const handleFollow = async () => {
    if (_post == null) return;

    if (_post?.user?.is_following) {
      setPost({ ..._post, user: { ..._post.user, is_following: false } } as IPost);
      await apiUserUnfollow(_post.user?.username ?? "");
      await apiTrack("user-unfollow", {});
    } else {
      setPost({ ..._post, user: { ..._post.user, is_following: true } } as IPost);
      await apiUserFollow(_post.user?.username ?? "");
      await apiTrack("user-follow", {});
    }
  }

  const handleLike = async () => {
    if (_post == null) return;

    if (_post?.like) {
      setPost({ ..._post, like: false, like_count: _post.like_count - 1 } as IPost);
      apiPostUnlike(_post?._id.toString()).then(([status, post]) => {
        setPost(post);
      });
      await apiTrack("post-unlike", { post_id: _post?._id?.toString() });
    } else {
      setPost({ ..._post, like: true, like_count: _post.like_count + 1 } as IPost);
      apiPostLike(_post?._id?.toString() ?? "").then(([status, post]) => {
        setPost(post);
      });
      await apiTrack("post-like", { post_id: _post?._id?.toString() });
    }
  }

  return (
    <div id={_post?.id} className="col-12 mb-4">
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="card-title">
            { _post != null && <PostUserComponent post={_post} handleFollow={handleFollow} /> }
          </div>
          <div className="card-text">
            <div className="px-3 py-2">
              { _post != null && (
                <>
                  <ContentComponent content={_post?.contents[0]} index={0} />
                  { _post.contents.length > 1 && (
                    <div className="mb-2">
                      <Button
                        href={`/posts/${_post?._id}`}
                        variant="outline-primary"
                      >
                        Read more...
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="card-footer">
            { _post != null && (
              <ToolComponent post={_post} handleLike={handleLike} />
            ) }
          </div>
        </div>
      </div>
    </div>
  )
}
