"use client";

import { apiTrack } from "@/lib/api-method"
import { useEffect, useState } from "react";

export default function Component(
  { name, params }: { name: string, params: {} }
) {
  useEffect(() => {
    (async () => {
      await apiTrack(name, params)
    })()
  })

  return (
    <></>
  )
}