"use client";

import { apiPosts } from "@/lib/api-method";
import { useEffect, useState } from "react";
import { IPost } from "@/models/post";
import PostComponent from "@/components/posts/post";
import PostPlaceholderComponent from "@/components/posts/post-placeholder";

export default function Component(
  { username }: { username?: string }
) {
  const [posts, setPosts] = useState<IPost[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);

    const last_create_at = posts.length > 0 ? posts[posts.length - 1].created_at : undefined;
    const [status, newPosts] = await apiPosts(last_create_at, username);
    
    if (newPosts.length === 0) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    setPosts([...posts, ...newPosts]);
    setIsLoading(false);
  };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop <= document.documentElement.offsetHeight - 10 || isLoading) {
      return;
    }
    fetchData();
  };

  useEffect(() => {
    if (posts.length === 0 && !isLoading) {
      fetchData();
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, posts]);

  return (
    <div>
      {posts.map((post) => (
        <PostComponent post={post} key={post._id.toString()} />
      ))}
      {isLoading && (
        <PostPlaceholderComponent />
      )}
    </div>
  );
}